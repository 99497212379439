import * as React from 'react';
import { twMerge } from 'tailwind-merge';

import { Currency as ICurrency } from '@customer-booking/__generated__/graphql';
import { Currency } from '@customer-booking/components/Currency';

const VehicleTypeOptionsRoot = ({
  children,
  className,
}: React.HTMLProps<HTMLUListElement>) => {
  return (
    <ul className={twMerge('flex flex-col text-primary', className)}>
      {children}
    </ul>
  );
};

const VehicleTypeOptionsItem = ({
  children,
  className,
}: React.HTMLProps<HTMLLIElement>) => {
  return (
    <li className={twMerge('flex items-baseline w-full', className)}>
      {children}
    </li>
  );
};

const VehicleTypeOptionsLabel = ({
  name,
  price,
  currency,
}: {
  name: string;
  price?: number;
  currency?: ICurrency;
}) => {
  return (
    <>
      <span className="flex-1">{name}</span>
      {currency && price !== undefined && (
        <strong>
          <Currency value={price} currency={currency} />
        </strong>
      )}
    </>
  );
};

export const VehicleTypeOptions = {
  Root: VehicleTypeOptionsRoot,
  Item: VehicleTypeOptionsItem,
  Label: VehicleTypeOptionsLabel,
};
