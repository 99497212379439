import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

export const MapSkeletonLayout = () => {
  return (
    <div className="flex flex-col-reverse md:flex-row h-full w-full relative">
      <div
        className={`w-full md:max-w-[480px] grow md:flex-1 relative p-4 bg-white`}
      >
        <div className="w-full text-center">
          <Skeleton className="mt-2" width={250} height={28} />
          <Skeleton className="mt-4" width={320} height={45} />
        </div>
        <div className="w-full mt-6">
          <Skeleton width={220} height={18} />
          <Skeleton className="mt-4 m-auto" width="100%" height={42} />
          <Skeleton className="mt-4 m-auto" width="100%" height={42} />
        </div>
      </div>
      <div className="w-full grow md:flex-1 min-h-[250px] bg-slate-100" />
    </div>
  );
};
