import * as React from 'react';
import { Toast } from '@hermes/ui';
import Confetti from 'react-confetti';
import { FormattedMessage } from 'react-intl';
import { useLocalStorage } from '@hermes/utils/hooks';

export const HappyNewYearToast = () => {
  const [shouldDisplayToast, setShouldDisplayToast] = useLocalStorage<boolean>(
    'happy-new-year-toast'
  );

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setShouldDisplayToast(false);
    }
  };

  const currentDateTime = new Date();
  const expirationDateTime = new Date('2024-01-15T00:00:00.000Z');

  const display = Boolean(
    (shouldDisplayToast ?? true) && currentDateTime < expirationDateTime
  );

  if (!display) {
    return null;
  }

  return (
    <Toast.Root
      className="overflow-hidden"
      duration={8_000}
      onOpenChange={handleOpenChange}
    >
      <Confetti
        width={340}
        height={90}
        numberOfPieces={300}
        gravity={0.04}
        recycle={false}
        className="-top-10"
      />
      <Toast.Close className="size-4" />
      <Toast.Title className="font-bold">
        <FormattedMessage
          description="Happy new year toast title"
          defaultMessage="Bonne année ! 🎉"
        />
      </Toast.Title>
      <Toast.Description>
        <FormattedMessage
          description="Happy new year toast description"
          defaultMessage="Toute l'équipe Marcel vous souhaite une bonne année 2024 et vous présente ses meilleurs vœux !"
        />
      </Toast.Description>
    </Toast.Root>
  );
};
