import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import NextLink from 'next/link';
import { useRouter } from 'next/navigation';

import Skeleton from 'react-loading-skeleton';
import { twMerge } from 'tailwind-merge';
import { useAuth, AuthProvider } from '@customer-booking/features/auth';
import { Button, Callout, DropdownMenu, IconButton } from '@hermes/ui';
import {
  ChevronDownIcon,
  CompanyIcon,
  QuestionIcon,
  SignOutIcon,
  UserOIcon,
  WarningCircleIcon,
} from '@hermes/icons/simple';
import { useLocale } from '@customer-booking/features/l10n';
import { useAppStore } from '@customer-booking/features/store';
import { useMapStore } from '@customer-booking/features/map';

import { ButtonLink } from '../ButtonLink';
import { MarceLogo } from '../MarcelLogo';
import { Link } from '../Link';
import { SelectLang } from '../SelectLang';
import { Nav } from '../Nav';
import { HappyNewYearToast } from '../HappyNewYearToast';

interface Infos {
  id: number;
  name: string;
  companyAdmin: boolean;
  useProBookingPage: boolean;
}

interface Company {
  infos?: Infos | null;
}

const adminCompanyFilter = (company: {
  infos?: { companyAdmin: boolean } | null;
}): company is { infos: Infos } =>
  Boolean(company.infos && company.infos.companyAdmin);

const UnauthenticatedItems = () => {
  const { localizePath } = useLocale();

  return (
    <>
      <SelectLang />
      <ButtonLink href={localizePath('/sign-in')} variant="accent">
        <FormattedMessage
          description="Sign in button"
          defaultMessage="Connexion"
        />
      </ButtonLink>
    </>
  );
};

const UserMenu = (profile: {
  firstName: string;
  companies?: Company[] | null;
  lastName: string;
  email: string;
  id: number;
  phoneNumberValid: boolean;
}) => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const { localizePath } = useLocale();
  const companies = profile.companies ?? [];
  const adminCompanies = companies.filter(adminCompanyFilter);

  const handleValidatePhoneNumber = () => {
    router.push(localizePath('/sign-up/validate-account'));
  };

  const handleHelpCenterClick = () => {
    router.push(localizePath('/help-center'));
  };

  return (
    <>
      <IconButton
        aria-label={formatMessage({
          description: 'Help center button',
          defaultMessage: 'Help center',
        })}
        onClick={handleHelpCenterClick}
      >
        <QuestionIcon className="fill-primary" />
      </IconButton>
      <SelectLang />
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <Button variant="secondary" className="flex items-center no-wrap">
            {!profile.phoneNumberValid && (
              <WarningCircleIcon className="size-5 fill-transparent stroke-accent stroke-2 mr-2" />
            )}
            <FormattedMessage
              description="Account Menu button"
              defaultMessage="{firstName}"
              values={{ firstName: profile.firstName }}
            />
            <ChevronDownIcon className="ml-1 size-4 stroke-primary" />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          className="min-w-[150px] max-w-[300px]"
          collisionPadding={10}
          sideOffset={4}
        >
          {!profile.phoneNumberValid && (
            <>
              <Callout size="sm" variant="warning" className="m-2 mb-3">
                <FormattedMessage
                  description="Validate your phone number warning"
                  defaultMessage="Veuillez valider votre numéro de téléphone pour réserver une course avec Marcel."
                />

                <Button
                  variant="accent"
                  size="xs"
                  className="block mt-2"
                  onClick={handleValidatePhoneNumber}
                >
                  <FormattedMessage
                    description="Validate your phone number button"
                    defaultMessage="Je valide mon numéro"
                  />
                </Button>
              </Callout>
              <DropdownMenu.Separator className="-mx-1" />
            </>
          )}
          {/* <DropdownMenu.Item className="justify-between gap-2" asChild>
            <Link className="no-underline" href={localizePath('/rides')}>
              <span className="flex items-center gap-2">
                <FormattedMessage
                  description="My rides menu item"
                  defaultMessage="Mes courses"
                />
                <NewTag />
              </span>
              <CarIcon className="size-5 ml-4 fill-primary" />
            </Link>
          </DropdownMenu.Item> */}
          <DropdownMenu.Item className="justify-between gap-2" asChild>
            <a
              href={`${process.env.NEXT_PUBLIC_MARCEL_CAB_ROOT_URL}/account/profile`}
            >
              <FormattedMessage
                description="My account menu item"
                defaultMessage="Mon compte"
              />
              <UserOIcon className="size-5 stroke-2 ml-4 fill-transparent stroke-primary" />
            </a>
          </DropdownMenu.Item>
          {adminCompanies.length > 1 ? (
            <>
              <DropdownMenu.Separator className="-mx-1" />
              <div className="text-primary rounded-sm flex items-center px-4 py-2">
                <FormattedMessage
                  description="My companies menu item"
                  defaultMessage="Mes comptes entreprises"
                />
                <CompanyIcon className="size-5 stroke-2 ml-4 fill-transparent stroke-primary" />
              </div>
              {adminCompanies.map((company) => (
                <DropdownMenu.Item
                  key={company.infos.id}
                  className="justify-between gap-2 pl-6"
                  asChild
                >
                  <a
                    className="!text-gray-500"
                    href={`${process.env.NEXT_PUBLIC_MARCEL_CAB_ROOT_URL}/company/${company.infos.id}/details`}
                  >
                    {company.infos.name}
                  </a>
                </DropdownMenu.Item>
              ))}
              <DropdownMenu.Separator className="-mx-1 my-1" />
            </>
          ) : adminCompanies.length === 1 ? (
            <DropdownMenu.Item className="justify-between gap-2 pl-6" asChild>
              <a
                className="!text-gray-500"
                href={`${process.env.NEXT_PUBLIC_MARCEL_CAB_ROOT_URL}/company/${adminCompanies[0].infos?.id}/details`}
              >
                <FormattedMessage
                  description="My company menu item"
                  defaultMessage="Mon compte entreprise"
                />
              </a>
            </DropdownMenu.Item>
          ) : undefined}
          <NextLink
            href="/sign-out"
            className="flex justify-between gap-2 w-full"
          >
            <DropdownMenu.Item className="w-full flex justify-between">
              <FormattedMessage
                description="Sign out button"
                defaultMessage="Déconnexion"
              />
              <SignOutIcon className="size-5 stroke-2 ml-4 fill-transparent stroke-primary" />
            </DropdownMenu.Item>
          </NextLink>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </>
  );
};

const AuthRootLayoutInner = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const { status, customerProfile } = useAuth();

  const loading = status !== 'authenticated' && status !== 'unauthenticated';

  const { localizePath } = useLocale();
  const { formatMessage } = useIntl();

  const resetAppStore = useAppStore((state) => state.reset);
  const resetMapStore = useMapStore((state) => state.reset);

  const resetState = () => {
    resetAppStore();
    resetMapStore();
  };

  const profile = customerProfile;

  const menu = loading ? (
    <Skeleton width={160} height={24} />
  ) : !profile ? (
    <UnauthenticatedItems />
  ) : (
    <UserMenu {...profile.customer.profile} />
  );

  return (
    <div className="flex flex-col min-h-full">
      <Nav className="relative min-h-[56px]">
        <Link
          href={localizePath('/')}
          className="mr-auto"
          aria-label={formatMessage({
            description: 'Home link',
            defaultMessage: 'Home',
          })}
          onClick={resetState}
        >
          <MarceLogo width={120} height={80} priority unoptimized />
        </Link>
        {menu}
      </Nav>
      <main className={twMerge('isolate flex-1', className)}>{children}</main>
    </div>
  );
};

export const AuthRootLayout = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <AuthProvider>
    <HappyNewYearToast />
    <AuthRootLayoutInner className={twMerge('flex flex-col', className)}>
      {children}
    </AuthRootLayoutInner>
  </AuthProvider>
);
