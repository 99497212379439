import * as React from 'react';
import { twMerge } from 'tailwind-merge';

export interface NavProps {
  children: React.ReactNode;
  className?: string;
}

export const Nav = ({ children, className }: NavProps) => {
  return (
    <nav
      className={twMerge(
        'z-10 flex w-full px-4 py-3 sticky top-0 gap-2 items-center border-b border-gray-100 backdrop-blur-sm bg-gradient-to-b from-white via-white to-[#f8f8f8]/75',
        className
      )}
    >
      {children}
    </nav>
  );
};
