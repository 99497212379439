import * as React from 'react';
import { ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';

import { useAuth } from '@customer-booking/features/auth/state/AuthContext';
import { AppErrorBoundaryFallback } from '@customer-booking/components/AppErrorBoundaryFallback';

import { useSyncRideRequest } from '../hooks/useSyncRideRequest';
import {
  BookingUIStateProvider,
  loadingAction,
  useBookingUIState,
} from '../context';

export const RideRequestBookingLayoutInner = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { customerProfile, status: authStatus } = useAuth();
  const { dispatch } = useBookingUIState();

  const { status: syncStatus } = useSyncRideRequest(
    authStatus,
    customerProfile?.customer.profile
  );

  const authDone =
    authStatus === 'authenticated' || authStatus === 'unauthenticated';

  React.useEffect(() => {
    dispatch(
      loadingAction(
        !authDone || ['idle', 'syncing', 'fetching'].includes(syncStatus)
      )
    );
  }, [authDone, syncStatus, dispatch]);

  return <>{children}</>;
};

export const RideRequestBookingLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <RollbarErrorBoundary fallbackUI={AppErrorBoundaryFallback}>
      <BookingUIStateProvider>
        <RideRequestBookingLayoutInner>
          {children}
        </RideRequestBookingLayoutInner>
      </BookingUIStateProvider>
    </RollbarErrorBoundary>
  );
};
