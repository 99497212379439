export const encodeObjectToBase64 = <T>(obj: T): string => {
  if (typeof window === 'undefined') {
    return Buffer.from(encodeURI(JSON.stringify(obj))).toString('base64');
  } else {
    return window.btoa(encodeURI(JSON.stringify(obj)));
  }
};

export const decodeObjectFromBase64 = <T>(base64: string) => {
  if (typeof window === 'undefined') {
    return JSON.parse(
      decodeURI(Buffer.from(base64, 'base64').toString('utf-8'))
    ) as T;
  } else {
    return JSON.parse(decodeURI(window.atob(base64))) as T;
  }
};
