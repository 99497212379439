import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import random from 'lodash.random';

const OptionSkeleton = React.memo(function OptionSkeletonInner() {
  return (
    <div className="flex items-center px-4 space-x-2 rounded-md border-2 py-6 border-gray-200">
      <Skeleton width={random(100, 200)} height={16} />
      <div className="flex-1" />
      <Skeleton width={32} height={16} />
      <Skeleton width={32} height={16} />
    </div>
  );
});

export const VehicleTypesSkeleton = () => (
  <div className="flex flex-col gap-2 space-y-2 min-h-[316px]">
    <OptionSkeleton />
    <OptionSkeleton />
    <OptionSkeleton />
  </div>
);
