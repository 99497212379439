import * as React from 'react';
import { MapSkeletonLayout } from './MapSkeletonLayout';

import { useBookingUIState } from '@customer-booking/features/booking/context';
import { Map } from '@customer-booking/features/map';
import { useWatchMedia } from '@hermes/utils/hooks';

export const MapLayout = ({ children }: { children: React.ReactNode }) => {
  const { loading } = useBookingUIState();
  const isDesktop = useWatchMedia('lg');

  if (loading) {
    return <MapSkeletonLayout />;
  }

  return (
    <div className="flex flex-col-reverse xl:flex-row xl:h-[calc(100dvh-61px)] xl:overflow-hidden w-full bg-white ">
      <div
        id="__leftScrollArea"
        className={`box-border w-full xl:max-w-[480px] grow p-4 xl:overflow-auto max-h-full xl:pb-0`}
      >
        {children}
      </div>
      <div className="w-full grow h-screen max-h-[300px] md:max-h-[400px] lg:max-h-[400px] xl:max-h-none relative -mt-[62px]">
        <Map.Root>
          <Map.Controls />
          <Map.Features />
          <Map.Markers hasDescription={isDesktop} />
          <Map.UserPosition />
        </Map.Root>
      </div>
    </div>
  );
};
