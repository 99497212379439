import { PrestigeIcon } from '@hermes/icons/colored';
import {
  BerlineIcon,
  BusinessIcon,
  CyclocabIcon,
  HybridIcon,
  SuitcaseIcon,
  UserIcon,
  VanIcon,
} from '@hermes/icons/simple';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';

const VehicleTypeItemRoot = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function VehicleTypeItemRootInner({ className, ...props }, ref) {
  return (
    <div
      ref={ref}
      className={twMerge('flex items-center gap-4', className)}
      {...props}
    />
  );
});

const VehicleTypeItemContent = ({
  className,
  ...props
}: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className={twMerge(
        'flex gap-4 justify-start items-center w-full overflow-hidden text-ellipsis',
        className
      )}
      {...props}
    />
  );
};

const VehicleTypeItemName = ({
  className,
  ...props
}: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className={twMerge('flex justify-center items-center gap-3', className)}
      {...props}
    />
  );
};

const VehicleTypeItemIcon = ({ type }: { type: string }) => {
  switch (type) {
    case 'berline-green':
      return <HybridIcon className="w-16 fill-transparent stroke-primary" />;
    case 'luxe':
      return <BusinessIcon className="w-16 fill-transparent stroke-primary" />;
    case 'van':
      return <VanIcon className="w-16 fill-transparent stroke-primary" />;
    case 'rickshaw':
      return <CyclocabIcon className="w-16 fill-primary stroke-none" />;
    case 'prestige':
      return <PrestigeIcon className="w-16 fill-transparent stroke-primary" />;
    case 'berline':
    default:
      return <BerlineIcon className="w-16 fill-transparent stroke-primary" />;
  }
};

export const VehicleTypeItemMaxPlaces = ({
  places,
  size = 4,
  className,
}: {
  places: number;
  className?: string;
  size?: number;
}) => {
  return (
    <div className={twMerge('ml-auto flex gap-1 items-center', className)}>
      {places}
      <UserIcon className={`size-${size} fill-primary`} />
    </div>
  );
};

export const VehicleTypeItemTagline = ({ tagline }: { tagline: string }) => {
  return <p className="text-gray-500 text-sm">{tagline}</p>;
};

export const VehicleTypeItemMaxLuggages = ({
  maxLuggages,
  size = 4,
}: {
  maxLuggages: number;
  size?: number;
}) => {
  return (
    <div className="flex gap-1 items-center">
      {maxLuggages}
      <SuitcaseIcon className={`size-${size} fill-primary`} />
    </div>
  );
};

export const VehicleTypeItem = {
  Root: VehicleTypeItemRoot,
  Content: VehicleTypeItemContent,
  Name: VehicleTypeItemName,
  Icon: VehicleTypeItemIcon,
  Tagline: VehicleTypeItemTagline,
  MaxPlaces: VehicleTypeItemMaxPlaces,
  MaxLuggages: VehicleTypeItemMaxLuggages,
};
