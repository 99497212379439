import { Combobox } from '@hermes/ui';
import * as React from 'react';
import { VehicleTypeItem } from './VehicleTypeItem';
import { twJoin } from 'tailwind-merge';
import { CheckCircleIcon, ChevronDownIcon } from '@hermes/icons/simple';
import { FormattedMessage } from 'react-intl';

export interface VehicleTypeItemType {
  maxPlaces: number;
  maxLuggages: number;
  logo: string;
  id: number;
  name: string;
  designation: string;
}

interface VehicleTypesComboboxProps {
  value?: string;
  name?: string;
  onChange?: (value: string) => void;
  options?: VehicleTypeItemType[];
  'aria-labelledby'?: string;
}

export const VehicleTypesCombobox = ({
  name,
  options,
  onChange,
  value,
  'aria-labelledby': ariaLabelledBy,
}: VehicleTypesComboboxProps) => {
  const valueOption =
    options?.find((option) => `${option.id}` === value) ?? options?.[0];

  if (!options?.length) return null;

  return (
    <Combobox.Root<string> selection={value} onSelectionChange={onChange}>
      <Combobox.Button className="group/trigger w-full">
        <VehicleTypeItem.Root
          name={name}
          className="w-full h-[70px]"
          aria-labelledby={ariaLabelledBy}
        >
          <VehicleTypeItem.Content
            className={
              'group-focus-visible/trigger:border-primary-dark group-focus/trigger:border-primary-dark rounded-md px-4 h-[70px] my-2 border-2 border-gray-200 items-center hover:border-gray-300 hover:shadow-sm active:shadow-sm transition-colors duration-200'
            }
          >
            <CheckCircleIcon
              aria-hidden="true"
              className="size-5 fill-accent"
            />
            {valueOption && (
              <div className="flex justify-start items-center grow flex-wrap xl:flex-nowrap">
                <VehicleTypeItem.Name className="justify-start grow mr-2">
                  <VehicleTypeItem.Icon type={valueOption.logo} />
                  {valueOption.designation}
                </VehicleTypeItem.Name>
                <div className="flex flex-wrap gap-4 justify-end items-center">
                  <VehicleTypeItem.MaxPlaces places={valueOption.maxPlaces} />
                  <VehicleTypeItem.MaxLuggages
                    maxLuggages={valueOption.maxLuggages}
                  />
                </div>
              </div>
            )}
            <ChevronDownIcon className="size-6 fill-primary group-hover/trigger:translate-y-1 transition-transform ease-in duration-150 group-data-[state='open']/trigger:rotate-180 group-data-[state='open']/trigger:translate-y-1" />
          </VehicleTypeItem.Content>
        </VehicleTypeItem.Root>
      </Combobox.Button>
      <Combobox.Popover
        title={
          <FormattedMessage
            defaultMessage="Choisissez votre véhicule"
            description="Vehicle type combobox title"
          />
        }
      >
        <Combobox.Options className="flex flex-col p-2 gap-2 rounded-lg">
          {options.map((type) => {
            return (
              <Combobox.Option
                key={type.id}
                value={`${type.id}`}
                className={twJoin(
                  'rounded-md px-4 py-0 border-2 border-transparent',
                  'hover:bg-transparent data-[comboboxui-state="selected"]:bg-transparent data-[comboboxui-state="active selected"]:bg-transparent data-[comboboxui-state="active"]:border-gray-200',
                  'hover:border-gray-200 aria-selected:bg-transparent aria-selected:border-accent-dark aria-selected:data-[comboboxui-state="active"]:border-accent-dark'
                )}
              >
                <VehicleTypeItem.Root className="w-full h-[70px]">
                  <VehicleTypeItem.Content>
                    <div className="flex justify-start items-center grow flex-wrap xl:flex-nowrap">
                      <VehicleTypeItem.Name className="justify-start mr-2 grow">
                        <VehicleTypeItem.Icon type={type.logo} />
                        {type.designation}
                      </VehicleTypeItem.Name>
                      <div className="flex flex-wrap gap-4 justify-end items-center">
                        <VehicleTypeItem.MaxPlaces places={type.maxPlaces} />
                        <VehicleTypeItem.MaxLuggages
                          maxLuggages={type.maxLuggages}
                        />
                      </div>
                    </div>
                  </VehicleTypeItem.Content>
                </VehicleTypeItem.Root>
              </Combobox.Option>
            );
          })}
        </Combobox.Options>
      </Combobox.Popover>
    </Combobox.Root>
  );
};
