import * as React from 'react';

import { ToggleButtonGroup } from '@hermes/ui';
import { VehicleTypeItem } from './VehicleTypeItem';
import { twMerge } from 'tailwind-merge';

export interface VehicleTypeOption {
  maxPlaces: number;
  maxLuggages: number;
  logo: string;
  id: number;
  name: string;
  designation: string;
  tagline: string;
}

export interface VehicleTypesListProps {
  value?: string;
  onChange?: (value: string) => void;
  options?: VehicleTypeOption[];
  className?: string;
}

export const VehicleTypesList = ({
  value,
  onChange,
  options,
  className,
}: VehicleTypesListProps) => {
  const handleValueChange = (value: string) => {
    if (value) onChange?.(value);
  };

  if (!options) return null;

  return (
    <ToggleButtonGroup.Root
      variant="card"
      spacing="none"
      orientation="vertical"
      className={twMerge('flex-col w-full p-0', className)}
      onValueChange={handleValueChange}
      value={value}
    >
      {options.map((type) => {
        return (
          <ToggleButtonGroup.Item
            key={type.id}
            value={`${type.id}`}
            className="grow"
          >
            <VehicleTypeItem.Root className="grow">
              <ToggleButtonGroup.ItemIndicator />
              <VehicleTypeItem.Content>
                <ToggleButtonGroup.ItemAnimate>
                  <VehicleTypeItem.Name>
                    <VehicleTypeItem.Icon type={type.logo} />
                    <div className="flex flex-col items-start">
                      {type.designation}
                      <VehicleTypeItem.Tagline tagline={type.tagline} />
                    </div>
                  </VehicleTypeItem.Name>
                </ToggleButtonGroup.ItemAnimate>
                <VehicleTypeItem.MaxPlaces places={type.maxPlaces} />
                <VehicleTypeItem.MaxLuggages maxLuggages={type.maxLuggages} />
              </VehicleTypeItem.Content>
            </VehicleTypeItem.Root>
          </ToggleButtonGroup.Item>
        );
      })}
    </ToggleButtonGroup.Root>
  );
};
