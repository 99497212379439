import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import { useLocale } from '@customer-booking/features/l10n';
import { ButtonSize, Select, getButtonClassNames } from '@hermes/ui';
import { getCountryFlag } from '@hermes/utils/methods';

export const SelectLang = ({ size = 'md' }: { size?: ButtonSize }) => {
  const { locale } = useLocale();
  const { formatMessage } = useIntl();
  const router = useRouter();

  const handleValueChange = (value: string) => {
    router.push({
      pathname: router.pathname,
      query: { ...router.query, locale: value },
    });
  };

  return (
    <Select.Root value={locale} onValueChange={handleValueChange}>
      <Select.Trigger
        className={`rounded-full  gap-1 min-w-0 ${getButtonClassNames({
          size,
          variant: 'secondary',
        })}`}
        aria-label={formatMessage({
          description: 'Language selector',
          defaultMessage: 'Changer de langue',
        })}
      >
        <Select.Value />
      </Select.Trigger>
      <Select.Content>
        <Select.Viewport>
          <Select.Item value="fr" className="gap-1">
            <Select.ItemText>{getCountryFlag('fr')}</Select.ItemText>
            Français
          </Select.Item>
          <Select.Item value="en" className="gap-1">
            <Select.ItemText>{getCountryFlag('gb')}</Select.ItemText>
            English
          </Select.Item>
        </Select.Viewport>
      </Select.Content>
    </Select.Root>
  );
};
