import { useAuth } from '@customer-booking/features/auth';

export const useFeatures = () => {
  const { customerProfile } = useAuth();

  const proBooking =
    customerProfile?.customer.profile.companies.some((company) => {
      return company.infos?.useProBookingPage;
    }) ?? false;

  return {
    proBooking,
  };
};
