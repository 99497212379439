import { Currency as ICurrency } from '@customer-booking/__generated__/graphql';
import { FormattedNumber } from 'react-intl';

interface CurrencyProps {
  value: number;
  currency: ICurrency;
  signDisplay?: 'auto' | 'always' | 'never' | 'exceptZero' | 'negative';
}

export const Currency = ({
  value,
  currency,
  signDisplay = 'auto',
}: CurrencyProps) => {
  return (
    <FormattedNumber
      value={value / 100}
      style="currency"
      currency={currency.name}
      signDisplay={signDisplay}
    />
  );
};
