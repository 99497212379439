/* eslint-disable import/no-default-export */
import * as React from 'react';

import Head from 'next/head';
import { useIntl } from 'react-intl';
import { GetServerSideProps } from 'next';
import 'react-loading-skeleton/dist/skeleton.css';

import {
  RideForm,
  RideFormValues,
} from '@customer-booking/features/booking/components/RideForm';
import { useCreateRideRequest } from '@customer-booking/features/booking/hooks/useCreateRideRequest';
import { useLocale } from '@customer-booking/features/l10n';
import { useAppStore } from '@customer-booking/features/store';
import { RideRequestBookingLayout } from '@customer-booking/features/booking/components/RideRequestBookingLayout';
import { CreateRideRequestMutation } from '@customer-booking/__generated__/graphql';
import { decodeObjectFromBase64 } from '@hermes/utils/base64';
import { useMapStore } from '@customer-booking/features/map';
import { useAnalytics } from '@hermes/analytics';

import type { NextPageWithLayout } from '../_app';
import { MapLayout } from '../../components/Layouts/MapLayout';
import { AuthRootLayout } from '../../components/Layouts/AuthRootLayout';
import { useAuth } from '@customer-booking/features/auth';
import { getISOPickUpDate } from '@customer-booking/features/booking/components/RideForm/helpers';
import { useRouter } from 'next/router';
import { useFeatures } from '@customer-booking/features/customer/hooks/useFeatures';
import { parseToCalendarDate, parseToTime } from '@hermes/utils/date';

const DashboardRedirect = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const { locale, ...urlQueryParams } = router.query;
  const { localizePath } = useLocale();
  const { customerProfile, status } = useAuth();
  const { proBooking } = useFeatures();

  const isUserAuthenticated = status === 'authenticated' && customerProfile;

  React.useEffect(() => {
    if (proBooking) {
      router.replace({
        pathname: localizePath('/dashboard'),
        query: urlQueryParams,
      });
    }
  }, [router, urlQueryParams, proBooking, localizePath]);

  if (!isUserAuthenticated) {
    return <>{children}</>;
  }

  if (proBooking) {
    return null;
  }

  return <>{children}</>;
};

const LocaleIndexPage: NextPageWithLayout<{
  query?: {
    p?: string;
  };
}> = ({ router, query }) => {
  const { formatMessage } = useIntl();
  const analytics = useAnalytics();
  const { customerProfile } = useAuth();

  const { localizePath } = useLocale();
  const setMap = useMapStore((state) => state.set);

  const defaultValues = React.useMemo(() => {
    const decoded = query?.p
      ? decodeObjectFromBase64<Record<string, unknown>>(query.p as string) ??
        undefined
      : undefined;

    if (!decoded) return;

    const date = decoded.rideDate
      ? parseToCalendarDate(decoded.rideDate as string)
      : undefined;
    const time = decoded.rideDate
      ? parseToTime(decoded.rideDate as string)
      : undefined;

    return { ...decoded, date, time } as RideFormValues;
  }, [query]);

  const title = formatMessage({
    description: 'Home page title',
    defaultMessage: 'Marcel - Réserver une course',
  });

  const [createRideRequest, { loading }] = useCreateRideRequest();
  const setRideRequest = useAppStore((state) => state.setRideRequest);

  const onSubmit = (values: RideFormValues) => {
    if (!values.trip.pickUpAddress) return;

    const rideDate =
      values.date && values.time
        ? getISOPickUpDate(values.date, values.time)
        : undefined;

    createRideRequest({
      variables: {
        input: {
          channel: 'Website',
          type: values.rideType,
          vehicleType: { id: +values.vehicleType },
          options: [],
          rideDate,
          addressDropOff: values.trip.dropOffAddress,
          addressPickUp: values.trip.pickUpAddress,
          flightId: values.flight?.flightId,
          flightRef: values.flight
            ? `${values.flight.flightCode}${values.flight.flightNumber}`
            : values.trainReference,
          stops: values.trip.stops
            ?.filter((v) => Boolean(v))
            .map((stop) => ({
              ...stop,
              address: stop.name,
            })),
        },
      },
      onCompleted: (data: CreateRideRequestMutation) => {
        setRideRequest(data.createRideRequestWeb);
        analytics.trackEvent(undefined, {
          customerId: customerProfile?.customer?.profile?.id,
          rideDate: data.createRideRequestWeb.pickUpDate
            ?.split('T')[0]
            ?.split('-')
            .reverse()
            .join('/'),
          vehicleType: data.createRideRequestWeb.vehicleType.id,
          price: data.createRideRequestWeb.price,
          transactionID: data.createRideRequestWeb.id,
          pickUpAddress: data.createRideRequestWeb.addressPickUp.name,
          dropOffAddress: data.createRideRequestWeb.addressDropOff?.name,
          step: 'quote',
        });

        router.push(localizePath('/booking'), undefined, {
          scroll: true,
        });
      },
    });
  };

  React.useEffect(() => {
    if (defaultValues) {
      setMap({
        pickUp: defaultValues.trip.pickUpAddress ?? undefined,
        dropOff: defaultValues.trip.dropOffAddress ?? undefined,
        stops: defaultValues.trip.stops ?? [],
      });
    }
  }, [defaultValues, setMap]);

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <RideForm.Root
        loading={loading}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
      >
        <RideForm.TypeField />
        <RideForm.DateField />
        <RideForm.LegsField />
        <RideForm.VehicleTypesField />
        <RideForm.SubmitButton />
      </RideForm.Root>
    </>
  );
};

LocaleIndexPage.getLayout = function getLayout(page: React.ReactElement) {
  return (
    <AuthRootLayout className="bg-white">
      <DashboardRedirect>
        <RideRequestBookingLayout>
          <MapLayout>{page}</MapLayout>
        </RideRequestBookingLayout>
      </DashboardRedirect>
    </AuthRootLayout>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { params, query } = context;

  return {
    props: {
      query: query ?? {},
      params: params ?? {},
    },
  };
};

export default LocaleIndexPage;
