import * as React from 'react';
import Link, { LinkProps } from 'next/link';
import { getButtonClassNames } from '@hermes/ui';
import { twMerge } from 'tailwind-merge';

export interface ButtonLinkProps extends LinkProps {
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'secondary' | 'accent';
  className?: string;
  children: React.ReactNode;
}

export const ButtonLink = ({
  size,
  variant,
  className,
  ...props
}: ButtonLinkProps) => {
  return (
    <Link
      className={twMerge(
        getButtonClassNames({ className, size, variant }),
        'inline-flex items-center'
      )}
      scroll
      {...props}
    />
  );
};
